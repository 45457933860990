window.addEventListener("DOMContentLoaded", () => {

    const accordions = document.querySelectorAll(".accordion-content button");
    accordions.forEach(item => item.addEventListener("click", toggleAccordion));

    function toggleAccordion(event) {

        var isExpanded = JSON.parse(event.target.getAttribute("aria-expanded"));
        isExpanded = !isExpanded;
        event.target.setAttribute("aria-expanded", isExpanded.toString());

        if (event.target.parentNode.parentNode.matches(".is-expanded")) {
            event.target.parentNode.parentNode.classList.remove("is-expanded");
        } else {
            accordions.forEach(item =>
                item.parentNode.parentNode.classList.remove("is-expanded")
            );
            event.target.parentNode.parentNode.classList.add("is-expanded");
            window.location.hash = event.target.id;
        }
    }

    if (window.location.hash) {
        const decodedHash = decodeURIComponent(window.location.hash);
        try {
            const scrollTop = $(decodedHash).offset().top - 110;
            window.scrollTo({
                top: scrollTop,
                left: 0
            });
            $(decodedHash).click();
        } catch (e) {
            console.log(`Could not find element with ID equal to: ${decodedHash}`);
        }

    }


});